
// @import '@radial-color-picker/vue-color-picker/dist/vue-color-picker.css';
#feeding {
  background: #fff;
}
.header{
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  .times{
      display: flex;
      .num{
        color:#3383FA;
        font-size: 4rem;
        font-weight: 600;
      }
      .unit{
        font-size: 0.8rem;
        color:#3383FA;
        font-weight: 500;
      }
      .weight{
        margin-top: 0.3rem;
        .text{
          color:#3383FA;
          font-size: 0.8rem;
        }
      }
  }
  .detail{
       margin-top: 2.2rem;
   }
}
.plan{
  padding: 20px 5px;
  width: 100%;
  display: flex;
  .time{
      flex: 1;
      font-size: 16px;
      color: #666;
  }
  .weight{
      width: 100px;
      font-size: 14px;
      color: #999;
  }
}
.btn-primary{
    background: #3383FA;
}
.btn-orange{
  background: orange;
}
.btn-blank{
    margin-top: 10px;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding:0.5rem;
    width: 60%;
    text-align: center;
    border:solid 1px #fff;
}
.colorPad {
  background: pink;
  background:-webkit-linear-gradient(#3383FA, #62B9FD);
  background:-o-linear-gradient(#3383FA, #62B9FD);
  background:-moz-linear-gradient(#3383FA, #62B9FD);
  background: linear-gradient(#3383FA, #62B9FD);
  height: 100%;
  overflow: hidden;
  padding-top: 5.2rem;
  .box {
    border-radius: 10px;
    min-height: 100px;
    background: #fff;
    margin: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .box .head {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    padding:0px 20px;
    align-items: center;
    border-bottom: #eee 1px solid;
  }
  .box .body {
    display: flex;
    justify-content: flex-start;
    padding:0 20px 10px 20px;
    align-items: center;
    flex: 1;
  }
  .box:first-child {
    margin-top: 0;
  }
}
